// Generated by Framer (8184e1b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["xa8nlM6Sh", "oTOXhXxKr", "zoYTRZV59", "FUIdc3Ser", "MeTIOntQr", "EqkIaTJTM"];

const serializationHash = "framer-NSTVf"

const variantClassNames = {EqkIaTJTM: "framer-v-1rxcpq8", FUIdc3Ser: "framer-v-hybzxm", MeTIOntQr: "framer-v-u2izdq", oTOXhXxKr: "framer-v-5d4dp9", xa8nlM6Sh: "framer-v-ck2tii", zoYTRZV59: "framer-v-1bplved"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Image 1:1": "EqkIaTJTM", "Image 16:9": "FUIdc3Ser", "Image 3:2": "zoYTRZV59", "Image 4:3": "oTOXhXxKr", "Image 4:5": "MeTIOntQr", Default: "xa8nlM6Sh"}

const getProps = ({height, id, image, mouseEnter, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "xa8nlM6Sh", vhrLqezAm: mouseEnter ?? props.vhrLqezAm, WKR34BYYJ: image ?? props.WKR34BYYJ} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string};mouseEnter?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, WKR34BYYJ, vhrLqezAm, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "xa8nlM6Sh", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnterslyzpm = activeVariantCallback(async (...args) => {
if (vhrLqezAm) {
const res = await vhrLqezAm(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><Image {...restProps} animate={variants} background={{alt: "", fit: "fill", sizes: "min(256px, 100vw)", ...toResponsiveImage(WKR34BYYJ)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-ck2tii", className, classNames)} data-framer-name={"Default"} data-highlight initial={variant} layoutDependency={layoutDependency} layoutId={"xa8nlM6Sh"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEnterslyzpm} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({EqkIaTJTM: {"data-framer-name": "Image 1:1"}, FUIdc3Ser: {"data-framer-name": "Image 16:9"}, MeTIOntQr: {"data-framer-name": "Image 4:5"}, oTOXhXxKr: {"data-framer-name": "Image 4:3"}, zoYTRZV59: {"data-framer-name": "Image 3:2"}}, baseVariant, gestureVariant)}/></Transition>
</LayoutGroup>)

});

const css = [".framer-NSTVf[data-border=\"true\"]::after, .framer-NSTVf [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-NSTVf.framer-ynadsk, .framer-NSTVf .framer-ynadsk { display: block; }", ".framer-NSTVf.framer-ck2tii { height: 256px; overflow: hidden; position: relative; width: 256px; }", ".framer-NSTVf.framer-v-5d4dp9.framer-ck2tii { aspect-ratio: 1.3333333333333333 / 1; height: var(--framer-aspect-ratio-supported, 192px); }", ".framer-NSTVf.framer-v-1bplved.framer-ck2tii { aspect-ratio: 1.4970760233918128 / 1; height: var(--framer-aspect-ratio-supported, 171px); }", ".framer-NSTVf.framer-v-hybzxm.framer-ck2tii { aspect-ratio: 1.7777777777777777 / 1; height: var(--framer-aspect-ratio-supported, 144px); }", ".framer-NSTVf.framer-v-u2izdq.framer-ck2tii { aspect-ratio: 0.8 / 1; height: var(--framer-aspect-ratio-supported, 320px); }", ".framer-NSTVf.framer-v-1rxcpq8.framer-ck2tii { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 256px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 256
 * @framerIntrinsicWidth 256
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"oTOXhXxKr":{"layout":["fixed","fixed"]},"zoYTRZV59":{"layout":["fixed","fixed"]},"FUIdc3Ser":{"layout":["fixed","fixed"]},"MeTIOntQr":{"layout":["fixed","fixed"]},"EqkIaTJTM":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"WKR34BYYJ":"image","vhrLqezAm":"mouseEnter"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramergtTB3sfvP: React.ComponentType<Props> = withCSS(Component, css, "framer-NSTVf") as typeof Component;
export default FramergtTB3sfvP;

FramergtTB3sfvP.displayName = "Image / Aspect Ratio";

FramergtTB3sfvP.defaultProps = {height: 256, width: 256};

addPropertyControls(FramergtTB3sfvP, {variant: {options: ["xa8nlM6Sh", "oTOXhXxKr", "zoYTRZV59", "FUIdc3Ser", "MeTIOntQr", "EqkIaTJTM"], optionTitles: ["Default", "Image 4:3", "Image 3:2", "Image 16:9", "Image 4:5", "Image 1:1"], title: "Variant", type: ControlType.Enum}, WKR34BYYJ: {title: "Image", type: ControlType.ResponsiveImage}, vhrLqezAm: {title: "Mouse Enter", type: ControlType.EventHandler}} as any)

addFonts(FramergtTB3sfvP, [])